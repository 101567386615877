exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-face-detection-tsx": () => import("./../../../src/pages/face-detection.tsx" /* webpackChunkName: "component---src-pages-face-detection-tsx" */),
  "component---src-pages-face-reading-checkout-tsx": () => import("./../../../src/pages/face-reading/checkout.tsx" /* webpackChunkName: "component---src-pages-face-reading-checkout-tsx" */),
  "component---src-pages-face-reading-index-tsx": () => import("./../../../src/pages/face-reading/index.tsx" /* webpackChunkName: "component---src-pages-face-reading-index-tsx" */),
  "component---src-pages-face-reading-paywall-tsx": () => import("./../../../src/pages/face-reading/paywall.tsx" /* webpackChunkName: "component---src-pages-face-reading-paywall-tsx" */),
  "component---src-pages-face-reading-quiz-tsx": () => import("./../../../src/pages/face-reading/quiz.tsx" /* webpackChunkName: "component---src-pages-face-reading-quiz-tsx" */),
  "component---src-pages-face-reading-summary-tsx": () => import("./../../../src/pages/face-reading/summary.tsx" /* webpackChunkName: "component---src-pages-face-reading-summary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-success-checkout-tsx": () => import("./../../../src/pages/success-checkout.tsx" /* webpackChunkName: "component---src-pages-success-checkout-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

